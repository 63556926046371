//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Message from '@/modules/complaint/components/complaint.vue';
// import MessagesNew from '@/modules/complaint/components/complaint-new.vue';
import MessagesListTable from '@/modules/complaint/components/complaint-list-table.vue';
import ComplaintModal from '@/modules/complaint/components/complaint-modal.vue';

import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['senderId'],
  name: 'app-messages-list',
  data() {
    return {
      newMessagesDialog: false,
      MessagesDialog: false,
      otherUserId: null,
      isNew: false,
      isClosed:false,
      complaintModalVisible:false,
      complaintDialogVisible:false,
      refetchComplaints: false
    };
  },
  created() {
    if (this.$route.query.senderId) {
      this.openMessage(this.$route.query.senderId);
    }
  },
  components: {
    [Message.name]: Message,
    [MessagesListTable.name]: MessagesListTable,
    [ComplaintModal.name]: ComplaintModal
  },
  methods: {
    ...mapActions({
      setOtherUserID: 'setOtherUserID',
      initNewChat: 'initNewChat',
      seenChat: 'seenChat',
      setMessagesListener:'complaint/setMessagesListener'
      
    }),
    ChangeState() {
      if (this.isNew) {
        this.isNew = false;
      }
    },
    openMessage(otherUserId,user,status, isNew = false) {
      this.otherUserId = otherUserId;
      this.user = user;
      this.isClosed = status=='closed' ? true : false;
      this.newMessagesDialog = false;
      this.isNew = isNew;
      if (window.innerWidth <= 599) {
        this.MessagesDialog = true;
      }
      this.setMessagesListener(otherUserId)
    },

    closeMessage(){
      this.otherUserId=null
    },
    onOpenModal() {
      this.complaintModalVisible = true;
      this.complaintDialogVisible = true;
    },
    onCloseModal() {
      this.complaintModalVisible = false;
      this.refetchComplaints = true;
      setTimeout(() => {
        this.complaintDialogVisible = false;
      }, 200);
    },
  },
  computed: {
    ...mapGetters({
      // channelData: 'complaint/channelData',
      currentUser: 'auth/currentUser',
      // openComplaint: 'complaint/openComplaint',
    }),
  },
};
