import { render, staticRenderFns } from "./complaint-list-page.vue?vue&type=template&id=2b62c7ad&scoped=true&"
import script from "./complaint-list-page.vue?vue&type=script&lang=js&"
export * from "./complaint-list-page.vue?vue&type=script&lang=js&"
import style0 from "./complaint-list-page.vue?vue&type=style&index=0&lang=css&"
import style1 from "./complaint-list-page.vue?vue&type=style&index=1&id=2b62c7ad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b62c7ad",
  null
  
)

export default component.exports
import {QBtn,QIcon,QCard,QCardSection,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QCard,QCardSection,QDialog})
