//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-messages-list-table',
  props: ['openMessage','closeMessage','refetchComplaints'],
  data() {
    return {
      textMessage: '',
      tab:'pending',
      selectedData: {},
      visibleDialog: false,
      Timer: true,
      isInit: true,
      confirm:false,
      selectedId:undefined,
      selectedValue:undefined,
    };
  },
  async mounted() {
    // this.doFetch({filter : {userType : 'client'}})
    await this.setComplaintListener({status :'pending',user:this.currentUser.id})
    await this.doFetchComplaintUser();
    setTimeout(() => {
      this.isInit = false,
      this.setTimerFalse()
    }, 0);
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
      currentUser: 'auth/currentUser',
      currentLanguage:'layout/currentLanguage',
      complaintsList:'complaint/complaintsList',
      complaintsArr:'complaint/complaintsArr',
      usersList:'iam/list/rows',
      complaintUser:'iam/list/complaintUser',
    }),
  },
  watch: {
    complaintsArr(oldVal,newVal){
      console.log(oldVal,newVal);
      if(oldVal.length != newVal.length) {
        this.doFetchComplaintUser();
      }
    },
    async refetchComplaints(newval){
      if(newval){
        await this.setComplaintListener({status :this.tab,user:this.currentUser.id})
      }
    }
  },
  methods: {
    ...mapActions({ setComplaintListener:'complaint/setComplaintListener',
    close:'complaint/close',
    doFetch:'iam/list/doFetch',
    doFetchComplaintUser:'complaint/doFetchComplaintUser'
    }),
    doChangeLoc(id){
      let value = !this.selectedValue
      this.close({id,value})
      this.confirm = false
      setTimeout(() => {
        let messageItem = this.complaintsArr.find(I => I.id === id)
        this.openMessage(messageItem.id,this.getUser(messageItem.createdBy),messageItem.status)
      }, 100);
    },
    doLock(id,value){
      if(value === true){
        this.confirm = true
        this.selectedId = id
        this.selectedValue = value
      }
      else{
        value = !value
        this.close({id,value})
        setTimeout(() => {
          let messageItem = this.complaintsArr.find(I => I.id === id)
          this.openMessage(messageItem.id,this.getUser(messageItem.createdBy),messageItem.status)
      }, 100);
      }
    },
    setTimerFalse(){
      this.Timer = false
    },
    getUser(id) {
      const user = this.usersList.find(I=>I.id === id || I.authenticationUid === id)
      if (user) {
        return user;
      }
      return {};
    },
     getAvatar(user) {
      // const user = this.usersList.find(I=>I.id === id || I.authenticationUid === id)
      if(user &&  user.avatars && user.avatars.length && user.avatars[0].publicUrl) return user.avatars[0].publicUrl      
        // return 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/defaultAvatar%2FdefaultAvatar.svg?alt=media&token=cfff16d3-2ebd-414c-9aea-4295499369c8';
        return '/images/defaultAvatar-01.svg';
      },
    openChat(data) {
      this.selectedData = data;
      this.visibleDialog = true;
    },
    i18n(label) {
      return i18n(label);
    },
    getLang() {
      return localStorage.getItem('language');
    },
    getStamp(date) {
      moment.lang(this.getLang());
      return moment(date, 'X').format('DD/MM/YYYY hh:mm A');
    },
  },
};
